import styled from '@emotion/styled'
import { rgba } from 'polished'

export const Input = styled.input(
  {
    borderRadius: 'calc((1rem / 3) * 2)',
    borderStyle: 'solid',
    borderWidth: '1px',
    height: '3rem',
    outline: 'none',
    padding: '.5rem 1rem',
    transition: 'border-color .35s',
    width: '100%',
  },
  ({ theme }) => {
    return {
      background: theme.isDark
        ? theme.colors.backgrounds.greyLight
        : theme.colors.white,
      borderColor: rgba(theme.colors.border.default, 0.5),
      color: theme.isDark ? theme.colors.white : theme.colors.text.body,

      '&:disabled': {
        backgroundColor: rgba(theme.colors.white, 0.05),
        color: rgba(theme.colors.white, 0.5),
      },

      '&:focus': {
        borderColor: theme.colors.info,

        '&:invalid': {
          borderColor: theme.colors.error,
        },
      },
    }
  },
)

export const TextArea = styled.textarea(
  {
    borderRadius: 'calc((1rem / 3) * 2)',
    borderStyle: 'solid',
    borderWidth: '1px',
    fontFamily: 'Inter, sans-serif',
    outline: 'none',
    padding: '15px',
    transition: 'border-color .35s',
    width: '100%',
  },
  ({ theme }) => ({
    backgroundColor: theme.isDark
      ? theme.colors.backgrounds.greyLight
      : theme.colors.white,
    borderColor: rgba(theme.colors.border.default, 0.5),
    color: theme.isDark ? theme.colors.white : theme.colors.text.body,

    '&:disabled': {
      backgroundColor: rgba(theme.colors.white, 0.05),
      color: rgba(theme.colors.white, 0.5),
    },

    '&:focus': {
      borderColor: theme.colors.info,

      '&:invalid': {
        borderColor: theme.colors.error,
      },
    },
  }),
)
