import styled from '@emotion/styled'
import { Button } from 'components/core/buttons'
import { rgba } from 'polished'

import { Input, TextArea } from '@components/core/forms/inputs'
import PageBreak from '@components/core/pageBreak'

export const Wrapper = styled.div(
  {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    right: 0,
    padding: '0 20px',
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 2,
  },
  ({ showNavigation }: any) => ({
    width: showNavigation ? 'calc(100% - 265px)' : '100%',
  }),
  ({ theme }) => ({
    '.wrapper-inner': {
      backgroundColor: theme.isDark ? theme.colors.black : null,
      boxShadow: theme.isDark
        ? '0 6px 12px 5px rgba(255, 255, 255, 0.05)'
        : null,

      '.cancel-button': {
        color: theme.isDark ? theme.colors.white : null,
      },

      '.small-text': {
        color: theme.isDark ? theme.colors.white : null,
      },

      '.hljs-string': {
        color: theme.isDark ? theme.colors.white : null,
      },

      '.hr-break': {
        borderBottomColor: theme.isDark ? theme.colors.text.body : null,
      },
    },
  }),
)

export const Background = styled.div({
  backdropFilter: 'blur(17px)',
  background: 'rgba(255, 255, 255, 0.03)',
  height: '100%',
  mixBlendMode: 'normal',
  position: 'absolute',
  width: '100%',
})

export const CloseIcon = styled.div({
  cursor: 'pointer',
  position: 'absolute',
  right: '24px',
  top: '24px',
  zIndex: 3,
})

export const ContentWrapper = styled.div(
  {
    boxShadow: '0px 6px 12px 5px rgba(2, 2, 2, 0.05)',
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 'calc(100% - 40px)',
    maxWidth: '780px',
    position: 'relative',
    width: '100%',

    '@media (max-width: 950px)': {
      maxHeight: 'calc(100% - 120px)',
    },
  },
  ({ theme }) => ({
    background: theme.colors.white,
    borderRadius: theme.borderRadius.default,
  }),
)

export const ContentWrapperChildren = styled.div({
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
  width: '100%',
})

export const Content = styled.div({
  display: 'flex',
  flexDirection: 'column',
  padding: '24px 32px',
})

export const Title = styled.span({
  fontSize: '1rem',
  fontWeight: 600,
  margin: '0 0 24px',

  '.font-weight-normal': {
    fontWeight: 400,
  },
})

export const Description = styled.p(
  {
    fontSize: '0.875rem',
    lineHeight: '1.5',
    margin: '0 0 10px',
  },
  ({ theme }) => ({
    color: theme.colors.text.body,
  }),
)

export const Form = styled.form(
  {
    display: 'flex',
    flexDirection: 'column',

    p: {
      margin: '0 0 10px',
    },
  },
  ({ theme }) => ({
    p: {
      color: theme.colors.text.body,
    },
  }),
)

export const ErrorText = styled.span(
  {
    margin: 0,
    lineHeight: '1.3',
  },
  ({ theme }) => ({
    color: theme.colors.text.red,
  }),
)

export const Label = styled.label(
  {
    fontSize: '0.875rem',
    margin: '10px 0',
  },
  ({ theme }) => ({
    color: theme.colors.text.body,
  }),
)

export const CenterAlignedLabel = styled(Label)({
  alignItems: 'center',
  display: 'flex',
})

export const StyledInput = styled(Input)(
  {
    margin: '0 0 10px',
    transition: 'all 0.3s ease-in-out',

    '&:focus': {
      border: '1px solid transparent',
    },

    '&.monospace': {
      fontFamily: 'monospace',
    },
  },
  ({ theme }) => ({
    background: theme.isDark ? theme.colors.backgrounds.greyLight : null,
    borderRadius: theme.borderRadius.default,
    color: theme.isDark ? theme.colors.white : theme.colors.black,

    '&:focus': {
      outline: `2px solid ${theme.colors.primary}`,
    },
  }),
)

export const StyledTextArea = styled(TextArea)(
  {
    margin: '0 0 10px',
    transition: 'all 0.3s ease-in-out',

    '&:focus': {
      border: '1px solid transparent',
    },
  },
  ({ theme }) => ({
    borderRadius: theme.borderRadius.default,

    '&:focus': {
      outline: `2px solid ${theme.colors.primary}`,
    },
  }),
)

export const DropdownWrapper = styled.div(
  {
    '.dropdown': {
      borderStyle: 'solid',
      borderWidth: '1px',
      margin: '0 0 10px',
      transition: 'all 0.3s ease-in-out',

      '&:focus': {
        border: '1px solid transparent',
      },
    },
  },
  ({ theme }) => ({
    '.dropdown': {
      background: theme.isDark
        ? theme.colors.backgrounds.greyLight
        : theme.colors.white,
      borderColor: rgba(theme.colors.border.default, 0.5),
      borderRadius: theme.borderRadius.default,
      color: theme.isDark ? theme.colors.white : theme.colors.text.body,

      '&:focus': {
        outline: `2px solid ${theme.colors.primary}`,
      },
    },
  }),
)

export const StyledPageBreak = styled(PageBreak)({
  margin: '25px -32px',
})

export const Footer = styled.div({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'flex-end',
  width: '100%',
})

export const CancelButton = styled(Button)(
  {
    border: 'none',
    fontSize: '0.95rem',
    marginRight: '10px',
    padding: '20px',
  },
  ({ theme }) => ({
    background: theme.colors.backgrounds.greyLight,
    color: theme.colors.text.button,

    '&:focus': {
      outline: `2px solid ${theme.colors.primary}`,
    },
  }),
)

export const SubmitButton = styled(Button)(
  {
    fontSize: '0.95rem',
    padding: '20px',
  },
  ({ theme }) => ({
    background: theme.colors.backgrounds.primaryLight,
    border: 'none',
    color: theme.colors.primary,

    '&:disabled': {
      background: theme.colors.backgrounds.greyLight,
      color: theme.colors.text.disabledButton,
    },

    '&:hover': {
      background: theme.colors.backgrounds.primary,
    },

    '&:focus': {
      outline: `2px solid ${theme.colors.primary}`,
    },
  }),
)

export const DangerButton = styled(Button)(
  {
    border: 'none',
    fontSize: '0.95rem',
    padding: '20px',
  },
  ({ theme }) => ({
    background: theme.colors.backgrounds.red,
    color: theme.colors.text.buttonRed,

    '&:hover': {
      background: theme.colors.backgrounds.redDark,
    },

    '&:focus': {
      outline: `2px solid ${theme.colors.primary}`,
    },
  }),
)
