import Image from 'next/image'
import { useEffect } from 'react'

import {
  Background,
  CloseIcon,
  ContentWrapper,
  ContentWrapperChildren,
  Wrapper,
} from './style'

type ModalProps = {
  children: React.ReactNode
  onClose?: () => void
  showNavigation?: boolean
  closeIconZIndex?: number
  modalZIndex?: number
}

const Modal = ({
  children,
  onClose,
  showNavigation = false,
  closeIconZIndex = 3,
  modalZIndex = 2,
}: ModalProps): JSX.Element => {
  // Close the modal when the user presses the escape key.
  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose()
      }
    }

    document.addEventListener('keydown', handleEscape)

    return () => {
      document.removeEventListener('keydown', handleEscape)
    }
  }, [onClose])

  return (
    <Wrapper style={{ zIndex: modalZIndex }} showNavigation={showNavigation}>
      <Background onClick={onClose} />
      <ContentWrapper className="wrapper-inner">
        <ContentWrapperChildren>{children}</ContentWrapperChildren>
        {onClose && (
          <CloseIcon style={{ zIndex: closeIconZIndex }} onClick={onClose}>
            <Image alt="Close" height={15} src="/icons/icon-x.svg" width={15} />
          </CloseIcon>
        )}
      </ContentWrapper>
    </Wrapper>
  )
}

export default Modal
